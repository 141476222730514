export function getUrlParams(search = window.location.search) {
	const hashes = search !== '' ? search.slice(search.indexOf('?') + 1).split('&') : [];
	let params = {};

	if(hashes.length > 0) {
		hashes.map(hash => {
			let [key, val] = hash.split('=');
			params[key] = decodeURIComponent(val);
		});
	}

	return params;
}

export const debugMode = getUrlParams().debugMode || false;

export function log(...args) {
	if(debugMode) {
		console.log.apply(console, args);
	}
}

export function domLoaded(callback) {
	if (document.readyState !== "loading") {
		// Document is already ready, call the callback directly
		callback();
	} else if (document.addEventListener) {
		// All modern browsers to register DOMContentLoaded
		document.addEventListener("DOMContentLoaded", callback);
	} else {
		// Old IE browsers
		document.attachEvent("onreadystatechange", function() {
			if (document.readyState === 'complete') {
				callback();
			}
		});
	}
}

export function windowLoaded(callback) {
	if (typeof window !== "undefined") {
		window.addEventListener("load", callback);
	}
}

const _toggleClass = (el, className, state) => {
	if (typeof state !== 'undefined') {
		if (state) {
			el.classList.add(className);
		} else {
			el.classList.remove(className);
		}
	} else {
		el.classList.toggle(className);
	}
}

export function toggleClass(el, className, state) {
	// Check if nodeList
	if (NodeList.prototype.isPrototypeOf(el)) {
		el.forEach(node => _toggleClass(node, className, state));
	} else {
		_toggleClass(el, className, state);
	}
}

export function offset(el) {
	return el.getBoundingClientRect();
}

const Utils = {
	domLoaded,
	windowLoaded,
	toggleClass,
	offset,
}

export default Utils;
