"use strict";
import LazyLoad from 'vanilla-lazyload';

import Utils from './Helpers/Utils';

const initImages = () => {
	const getImages = new LazyLoad();
}

/**
 * ------------------------------------------------------------------------
 * MOBILE TOGGLE
 * ------------------------------------------------------------------------
 */

const initMobileMenu = () => {
	const navPanel = document.querySelector('nav.panel');
	const navToggle = document.querySelector('.js-panel-show');
	const body = document.body;
	let modal = null;

	navToggle.addEventListener('click', (e) => {
		e.preventDefault();

		modal = document.createElement('div');
		modal.classList.add('modal-backdrop', 'js-panel-close', 'fade', 'show');

		body.appendChild(modal);
		body.classList.add('modal-open');

		navPanel.classList.add('show');
	});

	const panelClose = (e) => {
		if (e.target.closest('.js-panel-close')) {
			e.preventDefault();

			navPanel.classList.remove('show');
			body.classList.remove('modal-open');

			body.removeChild(modal);
		}
	};

	document.addEventListener('click', panelClose);
};

Utils.domLoaded(() => {
	initImages();
	initMobileMenu();
});

Utils.windowLoaded(() => {
});
